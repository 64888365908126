<template>
  <div class="join_step">
    <el-steps :active="activeStep" simple>
      <el-step title="阅读协议" icon="el-icon-notebook-2"></el-step>
      <el-step title="填写资料" icon="el-icon-edit"></el-step>
      <el-step title="等待审核" icon="el-icon-coffee-cup"></el-step>
      <el-step title="缴纳保证金" icon="el-icon-coin"></el-step>
      <el-step title="审核通过" icon="el-icon-finished"></el-step>
    </el-steps>
    <div class="main">
      <transition name="el-zoom-in-center">
        <router-view @getActiveStep="getActiveStep" :Contract="Contract"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
  import { getContractApi } from '@/api/api'
  export default {
    data() {
      return {
        activeStep: 0,
        Contract: {}
      }
    },
    created() {
      this.getContract()
    },
    methods: {
      getActiveStep(data) {
        this.activeStep = data
      },
      // 获取协议
      getContract() {
        getContractApi().then(res => {
          console.log(res)
          if (!res) return
          if (res.data.status === 90004) {
            this.$confirm(res.data.message, '提示', {
              confirmButtonText: '实名认证',
              cancelButtonText: '取消',
              type: 'warning',
              center: true
            }).then(() => {
              this.$router.push('/user/safe')
            }).catch(() => {
              this.$message.info('已取消');
              this.$router.go(-1)
            })
          } else {
            switch (res.data.result.step) {
            case 2:
              //   this.$router.push('/store/join/step_2')
              this.$router.replace('/store/join/step_2').catch(err => err)
              break;
            case 3:
              //   this.$router.push('/store/join/step_3')
              this.$router.replace('/store/join/step_3').catch(err => err)
              break;
            case 4:
              //   this.$router.push('/store/join/step_4')
              this.$router.replace('/store/join/step_4').catch(err => err)
              break;
            default:
              this.Contract = res.data.result
              //   this.$router.push('/store/join/step_1')
              this.$router.replace('/store/join/step_1').catch(err => err)
              break;
            }
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .join_step {
    width: 1200px;
    margin: 0 auto;
    color: #333;
    text-align: center;

    .el-steps {
      height: 150px;
    }
  }
</style>